import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import Sticky from 'react-stickynode';


import './App.css';

function App() {
  const useState = React.useState;
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const baseUrl = "https://api.mylocalai.com";
  // const baseUrl = "http://localhost:9181"; // backend api

  const [signupClicked, setSignupClicked] = useState(false);
  const onSignupClick = () => {
    setSignupClicked(true);
    fetch(`${baseUrl}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        comment: "THIS IS GREAT!",
        company: "companynamehere eventually"
      })
    }).catch((er) => {
      fetch(`${baseUrl}/reporterror`, {
        method: 'POST',
        body: JSON.stringify({
          error: { error: JSON.stringify(er), email },
          location: "signup-page"
        })
      }).catch(er => {
        console.error('err', er);
      })
    }).then((res) => {
      console.log('awww yea', res);
    });
  }
  return (
    <div className="App" >
      <div id="appBackground" className="AppBackground">
        <div className=" container text-center justify-content-center">
          <div className="h-100 row mainContentRow">
            <div className="col-sm-12">
              <div className="w-100">
                <Sticky enabled={true} top={0} innerZ={100}>
                  <div className="signupContainer">
                    <span className="signup">
                      <p>Sign up to stay up to date with our  progress</p>{!signupClicked ? <span>
                        <input type="text" onChange={(e) => {
                          setEmail(e.target.value);
                        }} placeholder="Enter Your Email Here"></input>
                        <button onClick={onSignupClick} type="submit">Sign up</button>
                      </span> : <span className="animated fadeInSignup">Thanks for signing up--we'll be in touch</span>
                      }
                    </span>
                  </div>
                </Sticky>
              </div>
            </div>
          </div>
          <div className="fullScreenHeight white">
            <div className="row  justify-content-center pb-2">
              <div id="privacy-minded" className="col-sm-12">
                <h1>
                  Privacy First
                </h1>
                <p>
                  We get it--you want to trust your LLM with your trade secrets.
                </p>
                <p>
                  We do not share, transmit, distribute, or otherwise give away your data(or metadata)--in any way shape or form--to anyone--including ourselves.
                </p>
                <p>
                  We keep no records of your interactions with MyLocalAI's Models. Period.
                </p>
                <p>
                  That's why we encrypt every connection from your computer to our backend.
                </p>

              </div>
            </div>

          </div>
          <div className="fullScreenHeight white">
            {/* <ScrollAnimation animateIn="fadeIn"> */}
            <div className="row  justify-content-center pb-2">
              <div id="zero-records" className="col-sm-12">
                <h1>
                  The No-Record LLM backend
                </h1>
                <p>
                  Think about it--who else will guarentee that they're not tracking you? Everyone seems to want your data.
                </p>
                <p>
                  We're different. We know that the best way to keep a customer is to provide you--the person paying us--best-in-class service.
                </p>
                <p>
                  Our architecture is by-design stateless. That means no tracking, no databases, and no record of your requests.
                </p>
                <p>
                  Go ahead--check the cookies and tracking for this site--we're not even using analytics.
                </p>
              </div>
            </div>
            {/* </ScrollAnimation> */}
          </div>
          <div className="fullScreenHeight">
            <ScrollAnimation animateIn="fadeIn">
              <div className="row  justify-content-center pb-2">
                <div id="aboutus" className="col-sm-12">
                  <h1>
                    MyLocalAI
                  </h1>
                  <p>
                    MyLocalAI's goal is to improve Business outcomes by empowering employees with AI agents, specific to their domain
                  </p>
                  <p>
                    This service, our "Bare Metal", is the entry-tier, and represents one-agent's worth of compute.
                  </p>
                  <p>
                    Additional agents can be configured on a per-need basis--get in touch today to reserve your instances.
                  </p>

                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div >
    </div>
  );
}

export default App;
